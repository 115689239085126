<template>
  <v-row>
    <v-dialog
      v-model="dialogOpen"
      scrollable
      persistent
    >
      <v-card
        tile
        class="note-detail"
      >
        <!-- This is the toolbar at the top of the detail dialog -->
        <v-app-bar
          flat
          color="white"
        >
          <v-btn
            icon

            @click="closeClicked"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="data.id">
            {{ led.internal_serialnumber }}: {{ data.subject }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="data.id"
            rounded
            :disabled="loading"
            text
            @click="openLEDExpDetails(data.id)"
          >
            Open Experiment
          </v-btn>
          <v-btn
            icon=""
            text
            :loading="loading"
            @click="refreshClicked"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- This is the content of the dialog -->
        <v-card-text>
          <v-container
            v-if="data.id"
            fluid
          >
            <v-row>
              <!-- This is the content text of the note -->
              <v-col
                cols="6"
              >
                <v-card
                  class="elevation-0"
                  style="border-radius: 8px; border: 1px solid lightgray; overflow: hidden;"
                >
                  <v-list
                    two-line
                    subheader
                  >
                    <v-subheader>Experiment by {{ data.username }} on {{ timeStringConv(data.timestamp) }}</v-subheader>

                    <v-list-group
                      no-action
                      prepend-icon="mdi-lightbulb-outline"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.title }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Tile</v-list-item-title>
                          <v-list-item-subtitle>{{ data.title }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="data.description"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Description</v-list-item-title>
                          <v-list-item-subtitle>{{ data.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="data.grouptitle"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Group</v-list-item-title>
                          <v-list-item-subtitle>{{ data.grouptitle }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="data.groupdescription"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Description</v-list-item-title>
                          <v-list-item-subtitle>{{ data.groupdescription }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      prepend-icon="mdi-current-dc"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Current settings ({{ data.current_shape }})</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Shape</v-list-item-title>
                          <v-list-item-subtitle>{{ data.current_shape }} (duration: {{ data.current_stepduration }} seconds, delay: {{ data.current_stepdelay }} seconds)</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Amplitude</v-list-item-title>
                          <v-list-item-subtitle>{{ data.current_min }} <span v-if="data.current_min !== data.current_max">- {{ data.current_max }}</span> mA ({{ data.current_type }}<span v-if="data.current_steps > 1">, {{ data.current_steps }} steps</span>)</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="data.current_shape !== 'dc'"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Pulses per {{ data.pulse_frequency }} seconds</v-list-item-title>
                          <v-list-item-subtitle>{{ data.pulses_min }} <span v-if="data.pulses_min !== data.pulses_max">- {{ data.pulses_max }}</span> ({{ data.pulse_type }}<span v-if="data.pulse_steps > 1">, {{ data.pulse_steps }} steps</span>)</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="data.current_shape !== 'dc'"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Duty cycle</v-list-item-title>
                          <v-list-item-subtitle>{{ data.duty_min }} <span v-if="data.duty_min !== data.duty_max">- {{ data.duty_max }}</span> % ({{ data.duty_type }}<span v-if="data.duty_steps > 1">, {{ data.duty_steps }} steps</span>)</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      prepend-icon="mdi-thermometer-lines"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.temperature_min }} <span v-if="data.temperature_min < data.temperature_max"> - {{ data.temperature_max }}</span> °C</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Temperature</v-list-item-title>
                          <v-list-item-subtitle>{{ data.temperature_min }} <span v-if="data.temperature_min < data.temperature_max"> - {{ data.temperature_max }}</span> °C ({{ data.temperature_type }})</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      prepend-icon="mdi-tune"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Custom preferences</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item
                        v-for="pref in custom"
                        :key="pref.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ pref.field }}: {{ pref.prefix }} {{ pref.value }} {{ pref.suffix }}</v-list-item-title>
                          <v-list-item-subtitle>{{ pref.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>

                  <v-divider />
                </v-card>

                <v-card
                  class="elevation-0 mt-4"
                  style="border-radius: 8px; border: 1px solid lightgray; overflow: hidden;"
                >
                  <v-list
                    three-line
                    subheader
                  >
                    <v-subheader>Files (Rack {{ data.rack }}, Slot {{ data.slot }})</v-subheader>

                    <v-list-group
                      v-for="file in data.files"
                      :key="file.id"
                      no-action
                      :prepend-icon="data.files[0].aos[0] ? 'mdi-playlist-check' : 'mdi-playlist-remove'"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ file.filetype }} <span class="grey--text text--lighten-1">&mdash; {{ file.name }}</span></v-list-item-title>
                            <v-list-item-subtitle><span class="text--primary">Collection UUID: {{ file.colluuid }}</span><br>{{ file.content }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item
                        v-for="ao in file.aos"
                        :key="ao.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ ao.ao_name }}</v-list-item-title>
                          <v-list-item-subtitle><span class="text--primary">{{ ao.ao_id }}</span><br>{{ ao.ao_desc }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="!data.files[0].aos[0]">
                        <v-list-item-content>
                          <v-list-item-title>File not yet uploaded</v-list-item-title>
                          <v-list-item-subtitle>No AOs available</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-card>

                <v-card
                  class="elevation-0 mt-4"
                  style="border-radius: 8px; border: 1px solid lightgray; overflow: hidden;"
                >
                  <div
                    v-for="file in data.files"
                    :key="file.id"
                  >
                    <div
                      v-if="file.filetypeid === '5' || file.filetypeid === '9' || file.filetypeid === '11'"
                    >
                      <correctedSpectrum
                        :led="led"
                        :data="data"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '3' || file.filetypeid === '15'"
                    >
                      <correctedIVP
                        :led="led"
                        :data="data"
                        :custom="custom"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '13'"
                    >
                      <correctedPulseNumber
                        :led="led"
                        :data="data"
                        :custom="custom"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '23'"
                    >
                      <reverseIVP
                        :led="led"
                        :data="data"
                        :custom="custom"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '17'"
                    >
                      <correctedPulseshape
                        :led="led"
                        :data="data"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '19'"
                    >
                      <lifetimeDownsampled
                        :led="led"
                        :data="data"
                      />
                    </div>
                    <div
                      v-else-if="file.filetypeid === '21'"
                    >
                      <lifetimeDownsampled
                        :led="led"
                        :data="data"
                      />
                    </div>
                    <div v-else>
                      <anythingElse
                        :led="led"
                        :data="data"
                      />
                    </div>
                  </div>
                </v-card>
              </v-col>

              <!-- This is the Plot and Matlab area -->
              <v-col
                cols="6"
              >
                <div
                  v-for="file in data.files"
                  :key="file.id"
                >
                  <v-card

                    class="elevation-0 mb-4"
                    style="border-radius: 8px; border: 1px solid lightgray; overflow: hidden;"
                  >
                    <v-list
                      three-line
                      subheader
                    >
                      <v-subheader>Measurement by {{ file.username }} on {{ timeStringConv(file.plottimestamp) }}</v-subheader>
                    </v-list>

                    <object
                      v-if="file.file1 && !loading"
                      class="gnuplotSVG"
                      :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file1+'&version=svg'+fetchSuffix"
                    />
                    <v-progress-circular
                      v-else-if="file.file1"
                      indeterminate
                    />
                    <div class="text-right">
                      <v-btn
                        v-if="file.file1"
                        :href="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file1+'&version=pdf'+fetchSuffix"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-file-pdf-box
                        </v-icon>
                        Download PDF
                      </v-btn>
                      <v-btn
                        v-if="file.file1"
                        :href="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file1+'&version=png'+fetchSuffix"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-image
                        </v-icon>
                        Download PNG
                      </v-btn>
                      <v-btn
                        v-if="file.colluuid1"
                        :href="file.colluuid1"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-code-braces
                        </v-icon>
                        Download AOs
                      </v-btn>
                    </div>
                    <object
                      v-if="file.file2 && !loading"
                      class="gnuplotSVG"
                      :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file2+'&version=svg'+fetchSuffix"
                    />
                    <v-progress-circular
                      v-else-if="file.file2"
                      indeterminate
                    />
                    <div class="text-right">
                      <v-btn
                        v-if="file.file2"
                        :href="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file2+'&version=pdf'+fetchSuffix"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-file-pdf-box
                        </v-icon>
                        Download PDF
                      </v-btn>
                      <v-btn
                        v-if="file.file2"
                        :href="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+file.file2+'&version=png'+fetchSuffix"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-image
                        </v-icon>
                        Download PNG
                      </v-btn>
                      <v-btn
                        v-if="file.colluuid2"
                        :href="file.colluuid2"
                        target="_blank"
                        depressed
                        rounded
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-code-braces
                        </v-icon>
                        Download AOs
                      </v-btn>
                    </div>
                    <v-card-text>
                      <SaveFlag
                        :loading="loading"
                        :data="file"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- This is the file preview dialog -->
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { EventBus } from '../scripts/event_bus'

export default {
  name: 'NoteDetailDialog',
  components: {
    SaveFlag: () => import('./SaveFlag'),
    correctedSpectrum: () => import('./matlabCode/correctedSpectrum'),
    anythingElse: () => import('./matlabCode/anythingElse'),
    correctedIVP: () => import('./matlabCode/correctedIVP'),
    correctedPulseNumber: () => import('./matlabCode/correctedPulseNumber'),
    correctedPulseshape: () => import('./matlabCode/correctedPulseshape'),
    lifetimeDownsampled: () => import('./matlabCode/lifetimeDownsampled'),
    reverseIVP: () => import('./matlabCode/reverseIVP')
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    custom: {
      type: Array,
      required: true,
      default: () => ([])
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      fetchSuffix: '',
      matlabCode: '',
      timestamp: 0,
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)

    if (localStorage.getItem('devAPIKey')) {
      this.fetchSuffix = '&apikey=' + localStorage.getItem('devAPIKey')
    }
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    closeClicked: function () {
      this.$emit('closeClicked')
    },
    refreshClicked: function () {
      this.$emit('refreshClicked')
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    openLEDExpDetails: function (expId) {
      this.$router.push({ name: 'run_exp', params: { exp_id: expId } })
    }
  }
}
</script>

<style scoped>

</style>
